const {USER_NAME_PATTERN} = require('../constants')
const {getAjaxJson, postAjaxJson} = require('./ajax')

function init(wixCodeApi) {
    let viewedUser = {},
        currentUser = {},
        userRoles = {}

    function getMenuCounters(user, santaMembersInstance) {
        if (user.loggedIn === false) {
            return Promise.resolve({})
        }
        return new Promise(resolve => {
            const userId = user && user.id
            if (userId && santaMembersInstance) {
                fetchNumbers(userId, santaMembersInstance, response => {
                    resolve(response)
                })
            }
        })
    }

    function fetchNumbers(userId, instance, successFn) {
        const baseURL = wixCodeApi.window.viewMode === 'Site' ? wixCodeApi.location.baseUrl : 'https://www.wix.com/'
        return getAjaxJson({
            baseURL,
            url: `${baseURL}/_api/santa-members-server/temporary/members/${userId}/numbers`,
            authorizationHeader: instance,
            onSuccess: successFn
        })
    }

    function fetchRoles(viewedUserId, loggedInUserId, instance) {
        return new Promise(resolve => {
            const baseURL = wixCodeApi.window.viewMode === 'Site' ? wixCodeApi.location.baseUrl : 'https://www.wix.com/'
            const payload = []
            if (viewedUserId) {
                payload.push(viewedUserId)
            }
            if (loggedInUserId) {
                payload.push(loggedInUserId)
            }
            postAjaxJson({
                baseURL,
                url: `${baseURL}/_api/santa-members-server/temporary/members/roles`,
                instance,
                payload,
                onSuccess: roles => {
                    resolve(roles)
                }
            })
        })
    }

    function replaceUserPatternWithSlug(url, user) {
        return url
            .replace(USER_NAME_PATTERN, user.slug)
            .replace(encodeURI(USER_NAME_PATTERN), user.slug)
    }

    function getViewedUser() {
        return viewedUser
    }

    function getCurrentUser() {
        return currentUser
    }

    function getRoles() {
        return userRoles
    }

    function setRoles(roles) {
        userRoles = roles
    }

    function setViewedUser(userData) {
        if (userData) {
            viewedUser = userData
        }
    }

    function setCurrentUser(userData, instance) {
        return Promise.resolve()
            .then(() => {
                currentUser.loggedIn = userData.loggedIn
                currentUser.id = userData.id
                return getCurrentUserSlug(userData, instance)
            })
            .then(slug => {
                currentUser.slug = slug
                return currentUser
            })
    }

    function fetchCurrentUserSlug(instance) {
        const baseURL = wixCodeApi.location.baseUrl
        return new Promise(resolve => getAjaxJson({
            baseURL,
            url: baseURL + '/_api/members/v1/members/my',
            authorizationHeader: instance,
            onSuccess: m => resolve(m.member?.profile?.slug)
        }))
    }

    function getCurrentUserSlug(userData, instance) {
        if (userData.loggedIn && userData.getSlug) {
            // Calling manually instead of userData.loggedIn.getSlug to not depend on their implementation
            // This was applied as a hotfix because of broken userData.loggedIn.getSlug implementation
            if (wixCodeApi.window.viewMode === 'Site') {
                return fetchCurrentUserSlug(instance)
                    .then(slug => slug || userData.id)
                    .catch(() => userData.id)
            }

            // For Preview mode we can't do the request so using default
            return userData.getSlug()
        }
        return userData.id
    }

    return {
        fetchNumbers,
        fetchRoles,
        getCurrentUser,
        getMenuCounters,
        getRoles,
        getViewedUser,
        replaceUserPatternWithSlug,
        setCurrentUser,
        setRoles,
        setViewedUser
    }
}

module.exports = {
    init
}
