let memoryStorage

function setMemoryStorage(storage) {
    memoryStorage = storage
}

function getMemoryStorage() {
    return memoryStorage
}

module.exports = {
    setMemoryStorage,
    getMemoryStorage
}
